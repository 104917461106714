import React, { useEffect, useRef } from "react";
import Pusher from "pusher-js";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { chatActions } from "redux/chat/actions";
import { store } from "react-notifications-component";
import { requestsActions } from "redux/requests/actions";
import { audioNotifRef, audioAlarmRef } from "index";
import { history } from "../redux/store";
import { notificationsActions } from "redux/notifications/actions";
import moment from "moment";
import { isAdmin } from "../constants";

const role = process.env.REACT_APP_ROLE;

// This is a custom hook to reference the previous state
// of a variable (in this file, used to check previous state
// of `authToken`)
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
let isRegisteredPusher = false;
let isRegisteredPusherBeams = false;
const PusherController = () => {
  let chatChannel = null;
  let pusher = null;
  const dispatch = useDispatch();
  const { user, isRehydrated, auth, isUserLoggedIn } = useSelector(
    (state) => state.user
  );

  const prevAuthToken = usePrevious(auth.access_token);

  useEffect(() => {
    if (auth.access_token && user && user.id && !isRegisteredPusherBeams) {
      isRegisteredPusherBeams = true;
    } else {
      if (!isUserLoggedIn) {
        isRegisteredPusherBeams = false;
        isRegisteredPusher = false;
      }
    }

    if (
      !auth.access_token &&
      prevAuthToken &&
      auth.access_token !== prevAuthToken
    ) {
      isRegisteredPusher = false;
      isRegisteredPusherBeams = false;
      if (chatChannel) {
        chatChannel.unbind_all();
      }
      if (pusher) {
        pusher.unsubscribe(`private-App.User.${user.id}`);
        // chatChannel.trigger("client-app-state", {
        //   user_id: user.id,
        //   state: "inactive",
        // });
      }
    }
  }, [auth, user]);

  useEffect(() => {
    if (!isRegisteredPusher && user && user.id && auth && auth.access_token) {
      subscribeChannels();
      isRegisteredPusher = true;
    } else {
      if (isRehydrated && !isUserLoggedIn) {
        isRegisteredPusher = false;
      }
    }
  }, [user]);

  const subscribeChannels = async () => {
    // console.log('messages form pusher : ', messages);

    const token = auth.access_token;
    const config = {
      appId: process.env.REACT_APP_PUSHER_APP_ID,
      enabledTransports: ["ws"],
      forceTLS: true,
      key: process.env.REACT_APP_PUSHER_KEY,
      secret: process.env.REACT_APP_PUSHER_SECRET,
      cluster: "eu",
      encrypted: true,
      authEndpoint: `${process.env.REACT_APP_BASE_URL}/broadcasting/auth`,
      auth: {
        params: { id_token: token },
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      },
    };
    pusher = new Pusher(config.key, config);
    var state = pusher.connection.state;
    // console.log('state : ', pusher);
    Pusher.logToConsole = false;
    chatChannel = pusher.subscribe(`private-App.User.${user.id}`);

    // const self = this;
    chatChannel.bind("pusher:subscription_succeeded", () => {
      // chatChannel.trigger("client-app-state", {
      //   user_id: user.id,
      //   state: "active",
      // });
      chatChannel.bind("new-message", (data) => {
        // if (chatChannel)
        //   chatChannel.trigger('client-message-received', {
        //     message_id: data.message.id,
        //   });
        dispatch(chatActions.getNewMessage(data.message));
      });
      chatChannel.bind("channel-created", (data) => {
        dispatch(chatActions.getNewChannel({ data: data.message }));
      });
      chatChannel.bind("channel-updated", (data) => {
        dispatch(chatActions.getUpdatedChannel({ data: data.message }));
      });
      chatChannel.bind("request-created", (data) => {
        dispatch(
          requestsActions.getRequestById(data.message.request_id, "add")
        );
        audioNotifRef.current.play();
        showNotification(
          "New Request",
          data.message.title,
          "success",
          false,
          dispatch,
          data.message.request_id,
          data.message.identifier,
          data.message.username
        );
        console.log("request-created : ", data);
      });
      chatChannel.bind("team-location-updated", (data) => {
        if (data.message.event === "team-sos") {
          audioAlarmRef.current.play();
          history.push(`/${role}/tracking`);
        } else if (data.message.event === "team-safe") {
          audioAlarmRef.current.pause();
        }
        dispatch(requestsActions.updateTeamLocation(data.message));
      });
      chatChannel.bind("request-updated", (data) => {
        const { event, status } = data.message;
        console.log("request-updated : ", data.message);
        // dispatch(
        //   requestsActions.updateTeamLocation({
        //     request_id: 98,
        //     request_shift_id: 42,
        //     request_team_id: 43,
        //     longitude: "4.8454807",
        //     latitude: "52.3798773",
        //   })
        // );

        if (event !== "request-history") {
          dispatch(
            requestsActions.getRequestById(data.message.request_id, "update")
          );
          audioNotifRef.current.play();

          showNotification(
            "",
            data.message.title,
            event === "request-canceled" || event === "team-sos"
              ? "danger"
              : event === "team-safe"
              ? "success"
              : "info",
            event === "team-sos" || event === "team-safe" ? true : false,
            dispatch,
            data.message.request_id,
            data.message.identifier,
            data.message.username
          );
        }

        const hash = window.location.hash;
        if (hash.indexOf("/tracking") !== -1) {
          let action = "update";
          if (status === "finished") {
            action = "finished";
          }
          dispatch(
            requestsActions.getActiveRequestById(
              data.message.request_id,
              action
            )
          );
        }
      });
    });
    // chatChannel.bind('pusher:member_added', function (member) {
    //   // for example:
    //   console.log('member_added : ', member);
    //   //add_member(member.id, member.info);
    // });
    // chatChannel.bind('pusher:member_removed', function (member) {
    //   // for example:
    //   console.log('member_removed : ', member);
    //   //add_member(member.id, member.info);
    // });
    chatChannel.bind("pusher:subscription_error", (status) => {
      // console.log('error outside if ', status);
      // if (status === 408 || status === 503) {
      // retry?
      // }
    });
    chatChannel.bind("pusher:state_change", (status) => {
      // console.log('error outside if ', status);
      // chatChannel.trigger("client-app-state", {
      //   user_id: user.id,
      //   state: "inactive",
      // });
      // if (status === 408 || status === 503) {
      // retry?
      // }
    });
  };

  return null;
};

export const showNotification = (
  title,
  message,
  type,
  notCancelled,
  dispatch,
  request_id,
  identifier,
  username
) => {
  if (isAdmin) {
    dispatch(
      notificationsActions.updateNotifications({
        message,
        request_id,
        identifier,
        username,
        created_at: moment().toLocaleString(),
      })
    );
  }

  store.addNotification({
    title,
    message,
    type: type || "info",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    //dismiss: undefined,
    dismiss: notCancelled
      ? undefined
      : {
          duration: 5000,
          onScreen: true,
          pauseOnHover: true,
        },
  });
};

export default PusherController;
