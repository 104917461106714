import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";

// core components
import Button from "components/CustomButtons/Button.js";
import {
  DialogActions,
  ListItemIcon,
  MenuItem,
  MenuList,
  Select,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import XLSX from "xlsx";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { useSelector, useDispatch } from "react-redux";
import { requestsActions } from "redux/requests/actions";
import {
  make_cols,
  prepareEscortRequest,
  prepareStaticRequest,
  SheetJSFT,
} from "./utils";
import EscortOutTable from "./EscortOutTable";
import StaticOutTable from "./StaticOutTable";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/
const DragDropFile = (props) => {
  function suppress(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }
  function onDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    const files = evt.dataTransfer.files;
    if (files && files[0]) props.handleFile(files[0]);
  }

  return (
    <GridContainer
      style={{
        border: "4px dashed #607D8B",
        height: 100,
        alignItems: "center",
        justifyContent: "center",
      }}
      onDrop={onDrop}
      onDragEnter={suppress}
      onDragOver={suppress}
    >
      {props.children}
    </GridContainer>
  );
};

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
const DataInput = (props) => {
  const fileInput = React.createRef();

  function handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) props.handleFile(files[0]);
  }
  return (
    <form className="form-inline">
      <input
        ref={fileInput}
        type="file"
        style={{ display: "none" }}
        accept={SheetJSFT}
        onChange={handleChange}
      />
      <span style={{ fontSize: 18 }}>
        Drag and Drop {props.selectedType} file or
      </span>
      <Button
        style={{ marginLeft: 20, marginTop: -3 }}
        color="primary"
        onClick={() => fileInput.current.click()}
      >
        Choose {props.selectedType} File
      </Button>
    </form>
  );
};

const UploadEscortRequests = (props) => {
  const classes = useStyles(styles);
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = React.useState(-1);
  const [data, setData] = React.useState([]);
  const [name, setName] = React.useState();
  const [cols, setCols] = React.useState([]);
  const [valid, setValid] = React.useState(false);
  const { user } = useSelector((state) => state.user);
  const { drivers } = useSelector((state) => state.drivers);
  const { trucks } = useSelector((state) => state.trucks);
  const { trailers } = useSelector((state) => state.trailers);
  const { saving, sendingSuccess } = useSelector((state) => state.requests);

  useEffect(() => {
    if (sendingSuccess) {
      props.onClose();
    }
  }, [sendingSuccess]);
  const onSubmit = () => {
    if (selectedType == "escort") {
      const requestList = prepareEscortRequest(
        data,
        user,
        drivers,
        trucks,
        trailers
      );
      dispatch(requestsActions.sendEscortRequestsExcel(requestList));
    }
    if (selectedType == "static") {
      const requestList = prepareStaticRequest(data, user);
      dispatch(requestsActions.sendStaticRequestsExcel(requestList));
    }
  };

  const onDownloadTemplate = (type) => {
    const downloadUrl = `https://protectrans.com/templates/${type}_template.xlsx`;
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", `protectrans_${type}_template.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleFile = (file /*:File*/) => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false });
      /* Update state */
      setName(file.name);
      setData(data);
      setCols(make_cols(ws["!ref"]));
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };
  return (
    <Dialog
      disableEnforceFocus={true}
      fullScreen
      open={props.isModalVisible}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onClose}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={props.onClose}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h3 className={classes.modalTitle}>
          Upload Excel requests (Escort/Static)
        </h3>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <GridItem>
          <div
            style={{
              marginBottom: 20,
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h4>Please choose the request type that you want to upload</h4>
              <Select
                placeholder="Clients"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedType}
                onChange={(event) => {
                  setSelectedType(event.target.value);
                  setData([]);
                  setName("");
                  setCols([]);
                  setValid(false);
                }}
              >
                <MenuItem value={-1}>Choose request type</MenuItem>
                <MenuItem value="escort">Escort</MenuItem>
                <MenuItem value="static">Static</MenuItem>
              </Select>
            </div>
            <div>
              <MenuList style={{ backgroundColor: "#d3d3d3" }}>
                <MenuItem onClick={() => onDownloadTemplate("escort")}>
                  <ListItemIcon>
                    <GetAppIcon color="primary" />
                  </ListItemIcon>
                  Download Escort Template
                </MenuItem>
                <MenuItem onClick={() => onDownloadTemplate("static")}>
                  <ListItemIcon>
                    <GetAppIcon color="primary" />
                  </ListItemIcon>
                  Download Static Template
                </MenuItem>
              </MenuList>
            </div>
          </div>
          {selectedType != -1 && (
            <DragDropFile handleFile={handleFile}>
              <GridItem
                style={{ borderWidth: 2, borderColor: "red" }}
                xs={12}
                sm={12}
                md={12}
              >
                <div className="col-xs-12">
                  <DataInput
                    selectedType={selectedType}
                    handleFile={handleFile}
                  />
                  <div style={{ fontWeight: "bold" }}>{name}</div>
                </div>
              </GridItem>
            </DragDropFile>
          )}
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {valid > 0 && (
            <div style={{ color: "red", marginTop: 20 }}>
              Your file contains {valid} errors, please fix them and import your
              file again
            </div>
          )}
          <div className="col-xs-12">
            {data.length > 0 && selectedType == "escort" && (
              <EscortOutTable setValid={setValid} data={data} cols={cols} />
            )}
            {data.length > 0 && selectedType == "static" && (
              <StaticOutTable setValid={setValid} data={data} cols={cols} />
            )}
          </div>
        </GridItem>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button
          onClick={props.onClose}
          variant="contained"
          color="primary"
          simple
        >
          Close
        </Button>
        {saving ? (
          <CircularProgress
            size={30}
            color="primary"
            style={{ marginRight: 30 }}
          />
        ) : (
          <Button
            disabled={valid > 0 || data.length == 0}
            onClick={onSubmit}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UploadEscortRequests;

UploadEscortRequests.prototype = {
  isModalVisible: PropTypes.bool,
};
