import React, { useEffect } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow,
  Polyline,
} from "react-google-maps";
import ImageScroller from "react-image-scroller";

import { compose, withProps, withHandlers, withState } from "recompose";
import { polylinecolors } from "redux/utils";
import { IMAGE_URL, primaryColor, REQUEST_HISTORY } from "../../constants";
const {
  MarkerClusterer,
} = require("react-google-maps/lib/components/addons/MarkerClusterer");

const groupBy = function(xs, key) {
  return (
    xs
      // .filter((x) => agents.includes(x.agent_id))
      .reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {})
  );
};
const CustomMap = ({
  locations,
  teamsLocations,
  selectedTeam,
  coords,
  checkedRouteAgents,
  teamHistoryChecked,
  teamHistory,
  selectedRequest,
  forceUpdate,
}) => {
  return (
    <CustomMapComponent
      teamsLocations={teamsLocations}
      selectedTeam={selectedTeam}
      coords={coords}
      locations={locations}
      checkedRouteAgents={checkedRouteAgents}
      teamHistory={teamHistory}
      teamHistoryChecked={teamHistoryChecked}
      selectedRequest={selectedRequest}
      forceUpdate={forceUpdate}
    />
  );
};

const CustomMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyALQVMLKyOi9LbwDsRX3Q2t9c3EcAEdH40&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `80vh` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withState("refMap", "setRefMap", null),
  withState("image", "setImage", null),
  withHandlers(() => {
    let refs = {
      map: undefined,
    };
    return {
      onMapMounted: ({ setRefMap }) => (ref) => {
        setRefMap(ref);
        refs.map = ref;
      },
      onZoomChanged: ({ onZoomChange }) => () => {
        onZoomChange(refs.map.getZoom());
      },
    };
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const LiveLocation = ({ location }) => (
    <div
      style={{
        // width: 150,
        backgroundColor:
          location?.sos == 1
            ? "#FF000050"
            : location?.sos == 2
            ? "#00FF0050"
            : "white",
        padding: 5,
        fontSize: 14,
        borderRadius: 5,
        color: primaryColor,
      }}
    >
      <div style={{ fontWeight: "bold" }}>{location.plate_number}</div>
      {location.agents
        .map((x) => x.agent)
        .map((agt, i) => (
          <div key={agt.id}>
            {i + 1}- {agt.first_name} {agt.last_name} {"\n"}
          </div>
        ))}
      <div
        style={{
          color: primaryColor,
          marginTop: 5,
          fontWeight: "bold",
        }}
      >
        {location.history_status}
      </div>
      {props.selectedTeam?.sos != 0 && (
        <div
          style={{
            color: location?.sos == 1 ? "red" : "green",
            fontWeight: "bold",
            fontSize: 18,
            marginTop: 5,
          }}
        >
          {location?.sos == 1 ? "SOS" : "SAFE"}
        </div>
      )}
    </div>
  );

  // const icon = {
  //   url:
  //     "https://images.vexels.com/media/users/3/154573/isolated/preview/bd08e000a449288c914d851cb9dae110-hatchback-car-top-view-silhouette-by-vexels.png",
  //   scaledSize: new window.google.maps.Size(40, 40),
  //   anchor: { x: 10, y: 10 },
  // };
  const blueMarker = new window.google.maps.MarkerImage(
    "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
    null /* size is determined at runtime */,
    null /* origin is 0,0 */,
    null /* anchor is bottom center of the scaled image */,
    new window.google.maps.Size(32, 32)
  );
  const orangeMarker = new window.google.maps.MarkerImage(
    "http://maps.google.com/mapfiles/ms/icons/orange-dot.png",
    null /* size is determined at runtime */,
    null /* origin is 0,0 */,
    null /* anchor is bottom center of the scaled image */,
    new window.google.maps.Size(32, 32)
  );
  const greenMarker = new window.google.maps.MarkerImage(
    "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
    null /* size is determined at runtime */,
    null /* origin is 0,0 */,
    null /* anchor is bottom center of the scaled image */,
    new window.google.maps.Size(32, 32)
  );
  const pinkMarker = new window.google.maps.MarkerImage(
    "http://maps.google.com/mapfiles/ms/icons/pink-dot.png",
    null /* size is determined at runtime */,
    null /* origin is 0,0 */,
    null /* anchor is bottom center of the scaled image */,
    new window.google.maps.Size(32, 32)
  );

  let locations = props.locations;
  let places = [...props.locations];
  const teamHistory = props.teamHistory.filter(
    (x) =>
      props.teamHistoryChecked.includes(x.id) && !!x.latitude && !!x.longitude
  );

  useEffect(() => {
    if (props.refMap) {
      const bounds = new window.google.maps.LatLngBounds();
      if (
        props.selectedTeam &&
        props.selectedTeam.id &&
        props.coords.length > 0
      ) {
        places = props.coords;
      }
      if (teamHistory.length > 0) {
        places = places.concat(
          teamHistory.map((x) => ({ lat: x.latitude, lng: x.longitude }))
        );
      }
      if (props.image) {
        places.push({
          lat: props.image.latitude,
          lng: props.image.longitude,
        });
      }
      if (places.length > 0) {
        places.forEach((place) => {
          bounds.extend(new window.google.maps.LatLng(place.lat, place.lng));
        });
        props.refMap.fitBounds(bounds);
      }
    }
  }, [props.forceUpdate]);

  const agentCoords = groupBy(
    props.coords,
    "agent_id",
    props.checkedRouteAgents
  );
  const generateIcon = (item) => {
    switch (item.history_status) {
      case REQUEST_HISTORY.STOP:
        return orangeMarker;
      case REQUEST_HISTORY.IMAGE:
        return pinkMarker;
      default:
        return greenMarker;
    }
  };
  return (
    <div style={{ position: "relative" }}>
      <GoogleMap
        options={{ maxZoom: 18, minZoom: 4 }}
        defaultZoom={10}
        ref={props.onMapMounted}
        defaultCenter={{ lat: 52.304724, lng: 4.676815 }}
        // onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, locations)}
      >
        <MarkerClusterer maxZoom={10} averageCenter enableRetinaIcons>
          {locations.map((location, index) => (
            <Marker
              // icon={icon}
              maxZoom={10}
              key={index}
              position={
                props.coords.length > 0
                  ? props.coords[props.coords.length - 1]
                  : location
              }
            >
              <InfoWindow
                // onCloseClick={props.onToggleOpen}
                options={{ closeBoxURL: ``, enableEventPropagation: true }}
              >
                <>
                  {location.plate_number && (
                    <LiveLocation location={location} />
                  )}
                  {/* {location.history_status && (
                  <HistoryLocation location={location} />
                )} */}
                </>
              </InfoWindow>
            </Marker>
          ))}
          {teamHistory.map((item) => (
            <Marker
              icon={generateIcon(item)}
              maxZoom={10}
              key={item.id}
              position={{
                lat: item.latitude,
                lng: item.longitude,
              }}
            >
              <InfoWindow
                options={{ closeBoxURL: ``, enableEventPropagation: true }}
              >
                <>
                  {item.history_status == REQUEST_HISTORY.IMAGE ? (
                    <img
                      width={100}
                      height={100}
                      src={`${IMAGE_URL}requests/'${props.selectedRequest.type}_${props.selectedRequest.identifier}/${item.image}`}
                    />
                  ) : (
                    <div>{item.history_status}</div>
                  )}
                </>
              </InfoWindow>
            </Marker>
          ))}
        </MarkerClusterer>
        {props.coords.length > 0 && props.selectedTeam && (
          <>
            {props.selectedTeam.agents.map((key, index) => (
              <>
                {props.checkedRouteAgents.includes(key.agent.id) && (
                  <Polyline
                    key={key}
                    path={agentCoords[key.agent.id]}
                    options={{ strokeColor: polylinecolors[index] }}
                  />
                )}
              </>
            ))}
          </>
        )}
      </GoogleMap>
    </div>
  );
});
export default CustomMap;
