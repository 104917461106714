import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, Paper, MenuList, ListItemIcon } from "@material-ui/core";
import { DateRangePicker } from "materialui-daterange-picker";
import DateRangeIcon from "@material-ui/icons/DateRange";

// @material-ui/icons
import Lottie from "react-lottie";
import Loading from "./loading.json";
import NoResults from "./noresults.json";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import CancelIcon from "@material-ui/icons/Cancel";
import BlockIcon from "@material-ui/icons/Block";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ArchiveIcon from "@material-ui/icons/Archive";
import PublishIcon from "@material-ui/icons/Publish";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import NavPills from "components/NavPills/NavPills";
import RequestItem from "./Requests/RequestItem";
import Button from "components/CustomButtons/Button";
import NewRequest from "./NewRequest/NewRequestDialog";
import { REQUEST_TYPE, REQUEST_STATUS } from "../../../src/constants";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { requestsActions } from "redux/requests/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Typography from "@material-ui/core/Typography";
import RequestSummaryDialog from "./NewRequest/RequestSummaryDialog.js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import UploadEscortRequests from "./Requests/UploadEscortRequests.js";
import { getClientActiveTabStatus } from "redux/utils.js";
import { getClientRequests } from "redux/requests/selectors.js";
import { whitelistClients } from "views/Admin/chat/ChatWidget";
import { chatActions } from "redux/chat/actions";

const styles = {
  trailer: {
    minWidth: 300,
    minHeight: 150,
  },
  truck: {
    width: 150,
    height: 150,
  },
};

const useStyles = makeStyles({
  ...styles,
  ...alertStyles,
});

const REQUEST_TYPE_INDEX = {
  0: "all",
  1: REQUEST_TYPE.MONITORING,
  2: REQUEST_TYPE.ESCORT,
  3: REQUEST_TYPE.STATIC,
  4: REQUEST_TYPE.INTERVENTION,
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Loading,
};
const noResultsOptions = {
  loop: true,
  autoplay: true,
  animationData: NoResults,
};
export default function Dashboard() {
  const [dateRange, setDateRange] = React.useState({
    startDate: moment()
      .add(-7, "days")
      .format("YYYY-MM-DD 00:00"),
    endDate: moment()
      .add(12, "months")
      .format("YYYY-MM-DD 23:59"),
  });
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isNewRequestVisible, setNewRequestVisible] = useState(false);
  const [isUploadRequestVisible, setUploadRequestVisible] = useState(false);
  const [requestSummaryVisible, setRequestSummaryVisible] = useState(false);
  const [editedRequest, setEditedRequest] = useState();
  const [selectedRequestType, setSelectedRequestType] = useState("all");
  const [selectedRequestTypeIndex, setSelectedRequestTypeIndex] = useState(0);
  const [selectedRequestStatusIndex, setSelectedRequestStatusIndex] = useState(
    0
  );
  const [alert, setAlert] = useState(null);
  const [cancelId, setCancelId] = useState(null);
  const [isFilterVisible, setFilterVisible] = useState(false);
  const {
    savingSuccess,
    sendingSuccess,
    cancelSuccess,
    fetching,
    editingSuccess,
    editingDateSuccess,
    cancelling,
  } = useSelector((state) => state.requests);

  const { user } = useSelector((state) => state.user);
  let grouppedRequests = useSelector(getClientRequests);
  // grouppedRequests[REQUEST_STATUS.ACCEPTED] = grouppedRequests[
  //   REQUEST_STATUS.ACCEPTED
  // ]
  //   ? grouppedRequests[REQUEST_STATUS.ACCEPTED].concat(
  //       grouppedRequests[REQUEST_STATUS.FINISHED] || []
  //     )
  //   : grouppedRequests[REQUEST_STATUS.ACCEPTED];
  const accepted = grouppedRequests[REQUEST_STATUS.ACCEPTED] || [];
  const finished = grouppedRequests[REQUEST_STATUS.FINISHED] || [];
  // grouppedRequests[REQUEST_STATUS.ACCEPTED] = [...finished.concat(accepted)];

  useEffect(() => {
    console.log("user from dashboard : ", user);
    // if (
    //   user &&
    //   user.client_id &&
    //   whitelistClients.includes(parseInt(user.client_id))
    // ) {
    dispatch(chatActions.setCurrentChannel(null));
    dispatch(chatActions.getActiveChannels());
    // }
  }, []);

  React.useEffect(() => {
    if (editingSuccess) {
      setAlert(
        <SweetAlert
          success
          title={`Request Updated!`}
          onConfirm={onDismissSave}
          onCancel={onDismissSave}
          closeOnClickOutside={false}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          Your request has been changed!
        </SweetAlert>
      );
    }
  }, [editingSuccess]);
  React.useEffect(() => {
    if (editingDateSuccess) {
      setAlert(
        <SweetAlert
          success
          title={`Request Date is changed!`}
          onConfirm={onDismissEditDate}
          onCancel={onDismissEditDate}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          Your request date has been changed!
        </SweetAlert>
      );
    }
  }, [editingDateSuccess]);
  useEffect(() => {
    if (savingSuccess || sendingSuccess) {
      setNewRequestVisible(false);
      setAlert(
        <SweetAlert
          success
          title={`Request(s) Sent!`}
          onConfirm={onDismissSave}
          onCancel={onDismissSave}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          Your request(s) has been sent, you will get a response from us very
          soon!
        </SweetAlert>
      );
    }
  }, [savingSuccess, sendingSuccess]);

  useEffect(() => {
    setCancelId(null);
    dispatch(requestsActions.resetSuccessCancel());
    // setAlert(
    //   <SweetAlert
    //     success
    //
    //     title={"Cancel request"}
    //     onConfirm={onDismissCancel}
    //     onCancel={onDismissCancel}
    //     confirmBtnCssClass={classes.button + " " + classes.success}
    //   >
    //     Your request has been canceled!
    //   </SweetAlert>
    // );
  }, [cancelSuccess]);

  const onDismissCancel = () => {
    setCancelId(null);
    dispatch(requestsActions.resetSuccessCancel());
  };
  const onCancel = (request) => {
    setCancelId(request.id);
  };

  const onOpenSummary = (request) => {
    dispatch(requestsActions.getRequestHistory(request.id));
    dispatch(requestsActions.searchRequestSuccess(request));
    setEditedRequest(request);
    setRequestSummaryVisible(true);
  };
  const onEdit = (request) => {
    setEditedRequest(request);
    setNewRequestVisible(true);
  };
  const onEditStartDate = (request) => {
    setEditedRequest(request);
    setNewRequestVisible(true);
  };

  const onDismissSave = () => {
    setAlert(null);
    dispatch(requestsActions.resetSuccessSave());
    dispatch(requestsActions.resetSuccessEditTransport());
  };
  const onDismissEditDate = () => {
    setAlert(null);
    dispatch(requestsActions.resetEditDateRequestSuccess());
  };

  const onFinish = (data) => {
    const newRequest = prepareNewRequest(data);
    if (editedRequest) {
      newRequest.id = editedRequest.id;
      dispatch(requestsActions.editRequests(newRequest));
    } else {
      dispatch(requestsActions.addRequests(newRequest));
    }
  };

  const onAddNewRequest = () => {
    setEditedRequest(null);
    setNewRequestVisible(true);
  };

  const prepareNewRequest = (data) => {
    const dop = data.step1.dop;
    if (data.step1.takeOverStartDate) {
      data.step1.dop = data.step1.takeOverDop;
      data.step1.takeOverDop = dop;
    }

    // return;
    let newRequest = {
      branch_id: user.branch_id,
      type: data.step1.selectedRequest,
      start_date: moment(data.step1.startDate).format("YYYY-MM-DD HH:mm"),
      take_over_start_date: data.step1.takeOverPup
        ? moment(data.step1.takeOverStartDate).format("YYYY-MM-DD HH:mm")
        : null,
      take_over_original_start_date: data.step1.takeOverPup
        ? moment(data.step1.takeOverStartDate).format("YYYY-MM-DD HH:mm")
        : null,
      pup: data.step1.pup,
      take_over_pup: data.step1.takeOverPup,
      take_over_dop: data.step1.takeOverDop,
      status: editedRequest ? editedRequest.status : 0,
    };
    if (newRequest.type === REQUEST_TYPE.ESCORT) {
      newRequest.escort_number = data.step1.guardsPerEscort;
      newRequest.dop = data.step1.dop;
    }
    if (newRequest.type === REQUEST_TYPE.INTERVENTION) {
      newRequest.escort_number = data.step1.guardsPerEscort;
    }
    if (newRequest.type === REQUEST_TYPE.MONITORING) {
      newRequest.monitoring_type = data.step2.monitoringType;
      newRequest.gps_url = data.step2.gps_url;
      newRequest.gps_username = data.step2.gps_username;
      newRequest.gps_password = data.step2.gps_password;
      newRequest.dop = data.step1.dop;
      if (data.step1.toDate) {
        newRequest.end_date = moment(data.step1.toDate).format(
          "YYYY-MM-DD HH:mm"
        );
      }
    }
    if (newRequest.type === REQUEST_TYPE.STATIC) {
      newRequest = {
        ...newRequest,
        end_date: moment(data.step1.toDate).format("YYYY-MM-DD HH:mm"),
        number_agents: data.step2.agentCount,
        responsible_name: data.step2.responsibleName,
        phone_number: data.step2.phoneNumber,
        instructions: data.step2.instruction,
      };
    } else {
      const { transports } = data.step2;
      let transportList = [];
      for (let i = 0; i < transports.length; i++) {
        let newTransport = {};
        const drivers = transports[i].drivers;
        for (let j = 0; j < drivers.length; j++) {
          const driverKey = "driver" + (j + 1);
          newTransport[driverKey] = {
            first_name: drivers[j].first_name,
            last_name: drivers[j].last_name,
          };
          if (drivers[j].id) {
            newTransport[driverKey].id = drivers[j].id;
          }
          if (drivers[j].phone_number) {
            newTransport[driverKey].phone_number = drivers[j].phone_number;
          }
        }
        newTransport.truck = {
          plate_number: transports[i].truck.plate_number,
        };
        if (transports[i].truck.id) {
          newTransport.truck.id = transports[i].truck.id;
        }
        if (transports[i].truck.brand) {
          newTransport.truck.brand = transports[i].truck.brand;
        }
        if (transports[i].truck.color) {
          newTransport.truck.color = transports[i].truck.color;
        }
        if (transports[i].trailer && transports[i].trailer.plate_number) {
          newTransport.trailer = {
            plate_number: transports[i].trailer.plate_number,
          };
          if (transports[i].trailer.id) {
            newTransport.trailer.id = transports[i].trailer.id;
          }
          if (transports[i].trailer.brand) {
            newTransport.truck.brand = transports[i].truck.brand;
          }
          if (transports[i].trailer.color) {
            newTransport.trailer.color = transports[i].trailer.color;
          }
        }
        transportList.push(newTransport);
      }
      newRequest.client_transport = transportList;
    }
    if (data.step3.note) {
      newRequest.notes = data.step3.note;
    }
    return newRequest;
  };

  const onFilterRequestTypeChange = (index) => {
    setSelectedRequestTypeIndex(index);
    setSelectedRequestType(REQUEST_TYPE_INDEX[index]);
  };

  const pendingRequestsCount = grouppedRequests[REQUEST_STATUS.PENDING]
    ? grouppedRequests[REQUEST_STATUS.PENDING].length
    : 0;
  let acceptedRequestsCount = accepted.length;

  const rejectedRequestsCount = grouppedRequests[REQUEST_STATUS.REJECTED]
    ? grouppedRequests[REQUEST_STATUS.REJECTED].length
    : 0;
  const canceledRequestsCount = grouppedRequests[REQUEST_STATUS.CANCELED]
    ? grouppedRequests[REQUEST_STATUS.CANCELED].length
    : 0;
  const archivedRequestsCount = grouppedRequests[REQUEST_STATUS.ARCHIVED]
    ? grouppedRequests[REQUEST_STATUS.ARCHIVED].length
    : 0;
  const checkedRequestsCount = grouppedRequests[REQUEST_STATUS.CHECKED]
    ? grouppedRequests[REQUEST_STATUS.CHECKED].length
    : 0;

  let requestsCount = grouppedRequests[
    getClientActiveTabStatus(selectedRequestStatusIndex)
  ]
    ? grouppedRequests[getClientActiveTabStatus(selectedRequestStatusIndex)]
        .length
    : 0;
  let requests =
    selectedRequestType !== "all" &&
    grouppedRequests[getClientActiveTabStatus(selectedRequestStatusIndex)]
      ? grouppedRequests[
          getClientActiveTabStatus(selectedRequestStatusIndex)
        ].filter((x) => x.type === selectedRequestType)
      : grouppedRequests[getClientActiveTabStatus(selectedRequestStatusIndex)];

  const toggle = () => setOpen(!open);
  const onRequestFilter = (range) => {
    toggle();
    setDateRange(range);
    if (range.startDate) {
      const startDate = moment(range.startDate).format("YYYY-MM-DD 00:00");
      const endDate = moment(range.endDate).format("YYYY-MM-DD 23:59");
      dispatch(requestsActions.fetchRequests({ startDate, endDate }));
    }
  };
  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Button onClick={toggle} color="primary" round>
            <DateRangeIcon />
            {dateRange.startDate
              ? `From ${moment(dateRange.startDate).format(
                  "DD/MM/YYYY"
                )} To ${moment(dateRange.endDate).format("DD/MM/YYYY")}`
              : "Select Loading Dates"}
          </Button>
          <DateRangePicker
            open={open}
            color="primary"
            toggle={toggle}
            onChange={(range) => onRequestFilter(range)}
          />
          <GridItem style={{ float: "right" }}>
            <Button
              onClick={onAddNewRequest}
              size="lg"
              color="primary"
              variant="contained"
            >
              Add New Request
            </Button>
            <Button
              onClick={() => setUploadRequestVisible(true)}
              size="lg"
              color="primary"
              variant="outlined"
            >
              Upload Multiple Requests
            </Button>
          </GridItem>
          <NavPills
            color="primary"
            alignCenter
            active={selectedRequestStatusIndex}
            onTabChange={setSelectedRequestStatusIndex}
            tabs={[
              {
                tabButton: `Pending (${pendingRequestsCount})`,
                tabIcon: MoreHorizIcon,
              },
              {
                tabButton: `Confirmed (${acceptedRequestsCount})`,
                tabIcon: BeenhereIcon,
              },
              {
                tabButton: `Rejected (${rejectedRequestsCount})`,
                tabIcon: BlockIcon,
              },
              {
                tabButton: `Canceled (${canceledRequestsCount})`,
                tabIcon: CancelIcon,
              },
              {
                tabButton: `Checked (${checkedRequestsCount})`,
                tabIcon: CheckCircleIcon,
              },
              {
                tabButton: `Archived (${archivedRequestsCount})`,
                tabIcon: ArchiveIcon,
              },
            ]}
          />
          <div>
            <NavPills
              onTabChange={onFilterRequestTypeChange}
              color="primary"
              active={selectedRequestTypeIndex}
              tabs={[
                {
                  tabButton: `All (${requestsCount})`,
                },
                {
                  tabButton: `Monitoring (${
                    requestsCount
                      ? grouppedRequests[
                          getClientActiveTabStatus(selectedRequestStatusIndex)
                        ].filter((x) => x.type === REQUEST_TYPE.MONITORING)
                          .length
                      : 0
                  })`,
                },
                {
                  tabButton: `Escort (${
                    requestsCount
                      ? grouppedRequests[
                          getClientActiveTabStatus(selectedRequestStatusIndex)
                        ].filter((x) => x.type === REQUEST_TYPE.ESCORT).length
                      : 0
                  })`,
                },
                {
                  tabButton: `Static (${
                    requestsCount
                      ? grouppedRequests[
                          getClientActiveTabStatus(selectedRequestStatusIndex)
                        ].filter((x) => x.type === REQUEST_TYPE.STATIC).length
                      : 0
                  })`,
                },
                {
                  tabButton: `Intervention (${
                    requestsCount
                      ? grouppedRequests[
                          getClientActiveTabStatus(selectedRequestStatusIndex)
                        ].filter((x) => x.type === REQUEST_TYPE.INTERVENTION)
                          .length
                      : 0
                  })`,
                },
              ]}
            />
            {!fetching && (!requests || !requests.length) && (
              <div>
                <Lottie options={noResultsOptions} height={300} width={300} />
                <Typography color="primary" style={{ textAlign: "center" }}>
                  No Requests found!
                </Typography>
              </div>
            )}
            {requests &&
              requests.map((item) => (
                <RequestItem
                  onOpenSummary={onOpenSummary}
                  onEditStartDate={onEditStartDate}
                  key={item.id + ""}
                  onEdit={onEdit}
                  onCancel={onCancel}
                  request={item}
                />
              ))}
          </div>
        </GridItem>
        {fetching && (
          <Lottie options={defaultOptions} height={300} width={300} />
        )}
      </GridContainer>
      {isNewRequestVisible && (
        <NewRequest
          onFinish={onFinish}
          onClose={() => setNewRequestVisible(false)}
          isModalVisible={isNewRequestVisible}
          request={editedRequest}
        />
      )}
      {isUploadRequestVisible && (
        <UploadEscortRequests
          onClose={() => setUploadRequestVisible(false)}
          isModalVisible={isUploadRequestVisible}
        />
      )}
      {requestSummaryVisible && (
        <RequestSummaryDialog
          onClose={() => setRequestSummaryVisible(false)}
          isModalVisible={requestSummaryVisible}
          request={editedRequest}
        />
      )}
      {alert}
      {cancelId && (
        <SweetAlert
          // dependencies={[cancelling]}
          input={!cancelling}
          //
          title={cancelling ? "Cancelling..." : "Are you sure?"}
          onConfirm={(reason) => {
            // setCancellingRequest("sfdgf");
            dispatch(requestsActions.cancelRequest({ id: cancelId, reason }));
          }}
          onCancel={onDismissCancel}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText="Yes, Cancel it!"
          cancelBtnText="Cancel"
          showCancel={!cancelling}
          showConfirm={!cancelling}
          closeOnClickOutside={false}
          // disabled={cancellingRequest}
        >
          {cancelling
            ? "Canceling the request, please wait..."
            : "Please type the reason of your cancellation!"}
        </SweetAlert>
      )}
      {cancelSuccess && (
        <SweetAlert
          success
          title={"Cancel request"}
          onConfirm={onDismissCancel}
          onCancel={onDismissCancel}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          Your request has been canceled!
        </SweetAlert>
      )}
    </div>
  );
}
