import momentDurationFormatSetup from "moment-duration-format";
import { createSelector } from "reselect";
import moment from "moment";
import {
  REQUEST_HISTORY,
  REQUEST_TYPE,
  REQUEST_HISTORY_KEY,
  REQUEST_STATUS,
  isSubContractor,
} from "../../constants";
const requestHistory = (state) => state.requests.requestHistory;
momentDurationFormatSetup(moment);

export const getRequests = createSelector(
  (state) => state.requests.requests,
  (requests) => groupBy(requests, "status")
);
export const getClientRequests = createSelector(
  (state) => state.requests.requests,
  (requests) => {
    let grouppedRequests = groupBy(requests, "status");
    grouppedRequests[REQUEST_STATUS.ACCEPTED] = grouppedRequests[
      REQUEST_STATUS.ACCEPTED
    ]
      ? grouppedRequests[REQUEST_STATUS.ACCEPTED].concat(
          grouppedRequests[REQUEST_STATUS.FINISHED] || []
        )
      : grouppedRequests[REQUEST_STATUS.ACCEPTED];

    return grouppedRequests;
  }
);
// export const getRequests = createSelector(
//   (state) => state.requests.requests,
//   (requests) => {
//     let grouppedRequests = groupBy(requests, "status");
//     if (isSubContractor) {
//       grouppedRequests[REQUEST_STATUS.FINISHED] = [
//         ...(grouppedRequests[REQUEST_STATUS.FINISHED] || []),
//         ...(grouppedRequests[REQUEST_STATUS.CHECKED] || []),
//         ...(grouppedRequests[REQUEST_STATUS.ARCHIVED] || []),
//       ];
//     }

//     return grouppedRequests;
//   }
// );

function removeDuplicates(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}
export const getActiveAgents = (activeRequests) => {
  const agents = activeRequests.map((item) =>
    item.agent_transport
      ? item.agent_transport.map((x) => {
          return { ...x.agent, team_id: x.request_teams_id };
        })
      : item
  );

  const reducedAgents = [].concat(...agents);

  return removeDuplicates(reducedAgents, "id");
};

export const getAllTeamLocation = createSelector(
  (state) => state.requests.activeRequests,
  (requests) => {
    let locations = [];
    for (let i = 0; i < requests.length; i++) {
      const requestShift = requests[i].request_shift;
      if (requestShift) {
        for (let j = 0; j < requestShift.length; j++) {
          const teams = requestShift[j].request_team;
          const grouppedLocation = teams
            .filter((x) => !!x.latitude)
            .map((x) => {
              return {
                lat: parseFloat(x.latitude),
                lng: parseFloat(x.longitude),
                plate_number: x.security_car.plate_number,
                agents: x.agents,
                id: x.id,
                history_status: x.history_status,
                shift_id: requestShift[j].id,
                sos: x.sos,
                team: x,
                //request_type: requestShift[j].request.type,
                //request_identifier: requestShift[j].request.identifier,
              };
            });
          locations = locations.concat(grouppedLocation);
        }
      }
    }
    return locations;
  }
);

// export const getActiveAgents = activeRequests => {
//   const agents = activeRequests.reduce(
//     (acc, curr) => acc.agent_transport.length + curr.agent_transport.length
//   );
//   return agents;
// };
// export const getTrackingRequests = createSelector(
//   state => state.requests.activeRequests,
//   requests =>
//     requests.filter(
//       x =>
//         x.request_shift.filter(
//           y => y.request_team.filter(z => !!z.history_status).length > 0
//         ).length > 0
//     )
// );
export const getTrackingRequests = createSelector(
  (state) => state.requests.activeRequests,
  (requests) =>
    requests.filter(
      (x) =>
        x.active_request_history.filter(
          (y) =>
            y.history_status === REQUEST_HISTORY_KEY.START_TRIP ||
            y.history_status === REQUEST_HISTORY_KEY.START_JOB ||
            y.history_status === REQUEST_HISTORY_KEY.ARRIVE_LOCATION
        ).length > 0
    )
);

export const getStatus = createSelector(
  [requestHistory],
  (requestHistory) => {
    if (requestHistory) {
      return requestHistory.filter(
        (x) =>
          (x.history_status !== REQUEST_HISTORY.IMAGE &&
            x.history_status !== REQUEST_HISTORY.NOTE &&
            x.history_status !== REQUEST_HISTORY.STOP_COST) ||
          (x.history_status == REQUEST_HISTORY.STOP_COST && !!x.cost)
      );
    }
    return [];
  }
);
function uniqBy(a, key) {
  return [...new Map(a.map((x) => [key(x), x])).values()];
}

export const getUserOffice = createSelector(
  (state) => state.user,
  ({ user }) => {
    return user.roles && user.roles[0] === "ROLE_OFFICE";
  }
);

export const getRequestRecords = createSelector(
  (state, requestType) => state.requests.requestHistory,
  (state, requestType) => requestType,
  (requestHistory, requestType) => {
    if (requestHistory) {
      // const filteredHistory = requestHistory.filter(
      //   x =>
      //     x.history_status !== REQUEST_HISTORY.NOTE &&
      //     x.history_status !== REQUEST_HISTORY.IMAGE &&
      //     x.history_status !== REQUEST_HISTORY.STOP &&
      //     x.history_status
      // );
      let records = [];
      if (
        requestType === REQUEST_TYPE.ESCORT ||
        requestType === REQUEST_TYPE.INTERVENTION
      ) {
        const grouppedCars = groupBy(requestHistory, "security_car_id");
        for (let i = 0; i < Object.keys(grouppedCars).length; i++) {
          const car_id = Object.keys(grouppedCars)[i];
          const car_history = grouppedCars[car_id];
          const securityTransport = grouppedCars[car_id][0].security_transport;
          const kilometers = uniqBy(
            car_history.filter((x) => x.start_kilometer),
            (x) => x.history_status
          );
          const stops = car_history.filter(
            (x) => x.history_status == REQUEST_HISTORY.STOP && !!x.finished_at
          );
          const arrivePUP = car_history.find(
            (x) => x.history_status == REQUEST_HISTORY_KEY.ARRIVE_PUP
          );
          const departPUP = car_history.find(
            (x) => x.history_status == REQUEST_HISTORY_KEY.DEPART_PUP
          );
          const arriveDOP = car_history.find(
            (x) => x.history_status == REQUEST_HISTORY_KEY.ARRIVE_DOP
          );
          const departDop = car_history.find(
            (x) => x.history_status == REQUEST_HISTORY_KEY.DEPART_DOP
          );
          let totalStops = 0;
          let pupWaitingTime = 0;
          let dopWaitingTime = 0;
          for (let i = 0; i < stops.length; i++) {
            totalStops += stopDuration(
              stops[i].created_at,
              stops[i].finished_at
            );
          }
          if (!!arrivePUP && !!departPUP) {
            pupWaitingTime = stopDuration(
              arrivePUP.created_at,
              departPUP.created_at
            );
            totalStops += pupWaitingTime;
          }
          if (!!arriveDOP && !!departDop) {
            dopWaitingTime = stopDuration(
              arriveDOP.created_at,
              departDop.created_at
            );
            totalStops += dopWaitingTime;
          }
          const totalWaitingTime =
            waitingDuration(totalStops) +
            "(W_PUP : " +
            waitingDuration(pupWaitingTime) +
            "(W_DOP : " +
            waitingDuration(dopWaitingTime) +
            ")";

          const costs = car_history.filter((x) => x.cost);
          let result = [];
          const grouppedCost = costs.reduce(function(res, value) {
            if (!res[value.currency]) {
              res[value.currency] = { currency: value.currency, cost: 0 };
              result.push(res[value.currency]);
            }
            res[value.currency].cost += parseFloat(value.cost);
            return res;
          }, {});
          const costLabel = Object.keys(grouppedCost)
            .map((key) => {
              const cost = grouppedCost[key];
              return `${cost.cost} ${cost.currency}`;
            })
            .join(" / ");
          let totalKilometers = "-";
          if (kilometers.length > 0)
            totalKilometers = kilometers
              .map((item) => item.start_kilometer)
              .reduce((prev, next) => parseFloat(prev) + parseFloat(next));
          let totalHours = "pending";
          try {
            const startTripDate = car_history.filter(
              (x) =>
                x.history_status == REQUEST_HISTORY.START_JOB ||
                x.history_status == REQUEST_HISTORY.START_TRIP
            )[0].created_at;
            const endTripDate = car_history.filter(
              (x) => x.history_status == REQUEST_HISTORY.FINISHED
            )[0].created_at;
            totalHours = tripDuration(startTripDate, endTripDate);
          } catch (e) {}
          const agentTransport = securityTransport.agent_transport;
          for (let j = 0; j < agentTransport.length; j++) {
            records.push([
              `${agentTransport[j].agent.first_name} ${agentTransport[j].agent.last_name}`,
              costLabel,
              totalHours,
              totalWaitingTime,
              totalKilometers,
              securityTransport.security_car.plate_number,
            ]);
          }
        }
        return records;
      } else {
        const grouppedAgents = groupBy(requestHistory, "agent_id");
        for (let i = 0; i < Object.keys(grouppedAgents).length; i++) {
          const agent_id = Object.keys(grouppedAgents)[i];
          const agent_history = grouppedAgents[agent_id];
          const agent = agent_history[0].agent;
          let totalKilometers = "-";
          const kilometers = uniqBy(
            agent_history.filter((x) => x.start_kilometer),
            (x) => x.history_status
          );
          if (kilometers.length > 0)
            totalKilometers = kilometers
              .map((item) => item.start_kilometer)
              .reduce((prev, next) => parseFloat(prev) + parseFloat(next));
          let totalHours = "pending";
          try {
            const startTripDate = agent_history.filter(
              (x) =>
                x.history_status == REQUEST_HISTORY.START_TRIP ||
                x.history_status == REQUEST_HISTORY.START_JOB
            )[0].created_at;
            const endTripDate = agent_history.filter(
              (x) => x.history_status == REQUEST_HISTORY.FINISHED
            )[0].created_at;
            totalHours = tripDuration(startTripDate, endTripDate);
          } catch (e) {}
          records.push([
            `${agent.first_name} ${agent.last_name}`,
            0,
            totalHours,
            "",
            totalKilometers,
            "-",
          ]);
        }
        return records;
      }
    }
    return [];
  }
);
export const getNewRequestRecords = createSelector(
  (state, requestType) => state.requests.requestHistory,
  (state, requestType) => requestType,
  (requestHistory, requestType) => {
    if (requestHistory) {
      let records = [];
      if (
        requestType === REQUEST_TYPE.ESCORT ||
        requestType === REQUEST_TYPE.INTERVENTION
      ) {
        const shifts = groupBy(
          requestHistory.filter((x) => !!x.security_car_id),
          "request_shift_id"
        );

        for (const [key, value] of Object.entries(shifts)) {
          const grouppedCars = groupBy(
            requestHistory.filter(
              (x) => !!x.security_car_id && x.request_shift_id == key
            ),
            "security_car_id"
          );
          for (let i = 0; i < Object.keys(grouppedCars).length; i++) {
            const car_id = Object.keys(grouppedCars)[i];
            const car_history = grouppedCars[car_id];
            const requestTeam = grouppedCars[car_id][i].request_team;
            const kilometers = uniqBy(
              car_history.filter((x) => x.start_kilometer),
              (x) => x.history_status
            );
            const stops = car_history.filter(
              (x) => x.history_status == REQUEST_HISTORY.STOP && !!x.finished_at
            );
            const arrivePUP = car_history.find(
              (x) => x.history_status == REQUEST_HISTORY_KEY.ARRIVE_PUP
            );
            const departPUP = car_history.find(
              (x) => x.history_status == REQUEST_HISTORY_KEY.DEPART_PUP
            );
            const arriveDOP = car_history.find(
              (x) => x.history_status == REQUEST_HISTORY_KEY.ARRIVE_DOP
            );
            const departDop = car_history.find(
              (x) => x.history_status == REQUEST_HISTORY_KEY.DEPART_DOP
            );
            let totalStops = 0;
            let pupWaitingTime = 0;
            let dopWaitingTime = 0;
            for (let i = 0; i < stops.length; i++) {
              // console.log(
              //   stopDuration(stops[i].created_at, stops[i].finished_at)
              // );
              totalStops += stopDuration(
                stops[i].created_at,
                stops[i].finished_at
              );
            }
            const totalStopsduringEscort = totalStops;
            if (!!arrivePUP && !!departPUP) {
              pupWaitingTime = stopDuration(
                arrivePUP.created_at,
                departPUP.created_at
              );
              totalStops += pupWaitingTime;
            }
            if (!!arriveDOP && !!departDop) {
              dopWaitingTime = stopDuration(
                arriveDOP.created_at,
                departDop.created_at
              );
              totalStops += dopWaitingTime;
            }

            const totalWaitingTime =
              waitingDuration(totalStops) +
              "( W_PUP : " +
              (waitingDuration(pupWaitingTime) || "0m") +
              " / W_DOP : " +
              (waitingDuration(dopWaitingTime) || "0m") +
              " / W_STOPS : " +
              (waitingDuration(totalStopsduringEscort) || "0m") +
              " )";
            let totalKilometers = "-";
            if (kilometers.length > 0)
              totalKilometers = kilometers
                .map((item) => item.start_kilometer)
                .reduce((prev, next) => parseFloat(prev) + parseFloat(next));
            let totalHours = "pending";
            try {
              const startTripDate = car_history.filter(
                (x) =>
                  x.history_status == REQUEST_HISTORY.START_JOB ||
                  x.history_status == REQUEST_HISTORY.START_TRIP
              )[0].created_at;
              const endTripDate = car_history.filter(
                (x) => x.history_status == REQUEST_HISTORY.FINISHED
              )[0].created_at;
              totalHours = tripDuration(startTripDate, endTripDate);
            } catch (e) {
              console.log("error tripDuration :", e);
            }
            const agentTransport = requestTeam.agents;
            for (let j = 0; j < agentTransport.length; j++) {
              const costs = car_history.filter(
                (x) => x.cost && x.agent_id == agentTransport[j].agent.id
              );
              let result = [];
              const grouppedCost = costs.reduce(function(res, value) {
                if (!res[value.currency]) {
                  res[value.currency] = { currency: value.currency, cost: 0 };
                  result.push(res[value.currency]);
                }
                res[value.currency].cost += parseFloat(value.cost);
                return res;
              }, {});
              const costLabel = Object.keys(grouppedCost)
                .map((key) => {
                  const cost = grouppedCost[key];
                  return `${cost.cost.toFixed(2)} ${cost.currency}`;
                })
                .join(" / ");
              records.push([
                `${agentTransport[j].agent.first_name} ${agentTransport[j].agent.last_name}`,
                costLabel,
                totalHours,
                totalWaitingTime,
                totalKilometers,
                requestTeam.security_car.plate_number,
              ]);
            }
          }
        }
        return records;
      } else {
        const grouppedAgents = groupBy(requestHistory, "agent_id");
        for (let i = 0; i < Object.keys(grouppedAgents).length; i++) {
          const agent_id = Object.keys(grouppedAgents)[i];
          const agent_history = grouppedAgents[agent_id];
          const agent = agent_history[0].agent;
          let totalKilometers = "-";
          const kilometers = uniqBy(
            agent_history.filter((x) => x.start_kilometer),
            (x) => x.history_status
          );
          if (kilometers.length > 0)
            totalKilometers = kilometers
              .map((item) => item.start_kilometer)
              .reduce((prev, next) => parseFloat(prev) + parseFloat(next));
          let totalHours = "pending";
          try {
            const startTripDate = agent_history.filter(
              (x) =>
                x.history_status == REQUEST_HISTORY.START_TRIP ||
                x.history_status == REQUEST_HISTORY.START_JOB
            )[0].created_at;
            const endTripDate = agent_history.filter(
              (x) => x.history_status == REQUEST_HISTORY.FINISHED
            )[0].created_at;
            totalHours = tripDuration(startTripDate, endTripDate);
          } catch (e) {}
          records.push([
            `${agent.first_name} ${agent.last_name}`,
            0,
            totalHours,
            "",
            totalKilometers,
            "-",
          ]);
        }
        return records;
      }
    }
    return [];
  }
);
export const excelRecords = (requestHistory) => {
  if (requestHistory) {
    const stops = requestHistory.filter(
      (x) => x.history_status == REQUEST_HISTORY.STOP && !!x.finished_at
    );
    const arrivePUP = requestHistory.find(
      (x) => x.history_status == REQUEST_HISTORY_KEY.ARRIVE_PUP
    );
    const departPUP = requestHistory.find(
      (x) => x.history_status == REQUEST_HISTORY_KEY.DEPART_PUP
    );
    const arriveDOP = requestHistory.find(
      (x) => x.history_status == REQUEST_HISTORY_KEY.ARRIVE_DOP
    );
    const departDop = requestHistory.find(
      (x) => x.history_status == REQUEST_HISTORY_KEY.DEPART_DOP
    );
    let totalStops = 0;
    let pupWaitingTime = 0;
    let dopWaitingTime = 0;
    for (let i = 0; i < stops.length; i++) {
      totalStops += stopDuration(stops[i].created_at, stops[i].finished_at);
    }
    const totalStopsduringEscort = totalStops;
    if (!!arrivePUP && !!departPUP) {
      pupWaitingTime = stopDuration(arrivePUP.created_at, departPUP.created_at);
      totalStops += pupWaitingTime;
    }
    if (!!arriveDOP && !!departDop) {
      dopWaitingTime = stopDuration(arriveDOP.created_at, departDop.created_at);
      totalStops += dopWaitingTime;
    }
    // try {
    //   const startTripDate = requestHistory.filter(
    //     x =>
    //       x.history_status == REQUEST_HISTORY.START_JOB ||
    //       x.history_status == REQUEST_HISTORY.START_TRIP
    //   )[0].created_at;
    //   const endTripDate = requestHistory.filter(
    //     x => x.history_status == REQUEST_HISTORY.FINISHED
    //   )[0].created_at;
    //   // totalHours = tripDuration(startTripDate, endTripDate);
    // } catch (e) {}
    const costs = requestHistory.filter((x) => x.cost);
    let result = [];
    const grouppedCost = costs.reduce(function(res, value) {
      if (!res[value.currency]) {
        res[value.currency] = { currency: value.currency, cost: 0 };
        result.push(res[value.currency]);
      }
      res[value.currency].cost += parseFloat(value.cost);
      return res;
    }, {});
    const costLabel = Object.keys(grouppedCost)
      .map((key) => {
        const cost = grouppedCost[key];
        return `${cost.cost.toFixed(2)} ${cost.currency}`;
      })
      .join(" / ");
    return {
      costLabel,
      waiting_pup: waitingExcelDuration(pupWaitingTime),
      waiting_dop: waitingExcelDuration(dopWaitingTime),
      waiting_escort: waitingExcelDuration(totalStopsduringEscort),
      waiting_total: waitingExcelDuration(totalStops),
    };
  }
  return [];
};
export const getNewClientRequestRecords = createSelector(
  (state, requestType) => state.requests.requestHistory,
  (state, requestType) => requestType,
  (requestHistory, requestType) => {
    if (requestHistory) {
      const filteredHistory = requestHistory.filter(
        (x) =>
          x.history_status !== REQUEST_HISTORY.NOTE &&
          x.history_status !== REQUEST_HISTORY.IMAGE &&
          x.history_status !== REQUEST_HISTORY.STOP &&
          x.history_status
      );
      let records = [];
      if (
        requestType === REQUEST_TYPE.ESCORT ||
        requestType === REQUEST_TYPE.INTERVENTION
      ) {
        const grouppedCars = groupByCar(filteredHistory, "security_car_id");
        for (let i = 0; i < Object.keys(grouppedCars).length; i++) {
          const car_id = Object.keys(grouppedCars)[i];
          const car_history = grouppedCars[car_id];
          const requestTeam = grouppedCars[car_id][0].request_team;
          const kilometers = uniqBy(
            car_history.filter((x) => x.start_kilometer),
            (x) => x.history_status
          );
          let totalKilometers = "-";
          if (kilometers.length > 0)
            totalKilometers = kilometers
              .map((item) => item.start_kilometer)
              .reduce((prev, next) => parseFloat(prev) + parseFloat(next));
          let totalHours = "pending";
          try {
            const startTripDate = car_history.filter(
              (x) =>
                x.history_status == REQUEST_HISTORY.START_JOB ||
                x.history_status == REQUEST_HISTORY.START_TRIP
            )[0].created_at;
            const endTripDate = car_history.filter(
              (x) => x.history_status == REQUEST_HISTORY.FINISHED
            )[0].created_at;
            const agentTransport = requestTeam.agents;

            totalHours = tripDuration(
              startTripDate,
              endTripDate,
              agentTransport.length
            );
          } catch (e) {}
          // let recordsArray = [];
          records.push([
            totalHours,
            totalKilometers,
            requestTeam.security_car.plate_number,
          ]);
        }
        return records;
      } else {
        const grouppedAgents = groupBy(filteredHistory, "agent_id");
        for (let i = 0; i < Object.keys(grouppedAgents).length; i++) {
          const agent_id = Object.keys(grouppedAgents)[i];
          const agent_history = grouppedAgents[agent_id];
          const agent = agent_history[0].agent;
          let totalKilometers = "-";
          const kilometers = uniqBy(
            agent_history.filter((x) => x.start_kilometer),
            (x) => x.history_status
          );
          if (kilometers.length > 0)
            totalKilometers = kilometers
              .map((item) => item.start_kilometer)
              .reduce((prev, next) => parseFloat(prev) + parseFloat(next));
          let totalHours = "pending";
          try {
            const startTripDate = agent_history.filter(
              (x) =>
                x.history_status == REQUEST_HISTORY.START_TRIP ||
                x.history_status == REQUEST_HISTORY.START_JOB
            )[0].created_at;
            const endTripDate = agent_history.filter(
              (x) => x.history_status == REQUEST_HISTORY.FINISHED
            )[0].created_at;
            totalHours = tripDuration(startTripDate, endTripDate);
          } catch (e) {}
          records.push([
            `${agent.first_name} ${agent.last_name}`,
            totalHours,
            totalKilometers,
            "-",
          ]);
        }
        return records;
      }
    }
    return [];
  }
);
export const getClientRequestRecords = createSelector(
  (state, requestType) => state.requests.requestHistory,
  (state, requestType) => requestType,
  (requestHistory, requestType) => {
    if (requestHistory) {
      const filteredHistory = requestHistory.filter(
        (x) =>
          x.history_status !== REQUEST_HISTORY.NOTE &&
          x.history_status !== REQUEST_HISTORY.IMAGE &&
          x.history_status !== REQUEST_HISTORY.STOP &&
          x.history_status
      );
      let records = [];
      if (
        requestType === REQUEST_TYPE.ESCORT ||
        requestType === REQUEST_TYPE.INTERVENTION
      ) {
        const grouppedCars = groupBy(filteredHistory, "security_car_id");
        for (let i = 0; i < Object.keys(grouppedCars).length; i++) {
          const car_id = Object.keys(grouppedCars)[i];
          const car_history = grouppedCars[car_id];
          const securityTransport = grouppedCars[car_id][0].security_transport;
          const kilometers = uniqBy(
            car_history.filter((x) => x.start_kilometer),
            (x) => x.history_status
          );
          let totalKilometers = "-";
          if (kilometers.length > 0)
            totalKilometers = kilometers
              .map((item) => item.start_kilometer)
              .reduce((prev, next) => parseFloat(prev) + parseFloat(next));
          let totalHours = "pending";
          try {
            const startTripDate = car_history.filter(
              (x) =>
                x.history_status == REQUEST_HISTORY.START_JOB ||
                x.history_status == REQUEST_HISTORY.START_TRIP
            )[0].created_at;
            const endTripDate = car_history.filter(
              (x) => x.history_status == REQUEST_HISTORY.FINISHED
            )[0].created_at;
            const agentTransport = securityTransport.agent_transport;

            totalHours = tripDuration(
              startTripDate,
              endTripDate,
              agentTransport.length
            );
          } catch (e) {}
          // let recordsArray = [];
          records.push([
            totalHours,
            totalKilometers,
            securityTransport.security_car.plate_number,
          ]);
        }
        return records;
      } else {
        const grouppedAgents = groupBy(filteredHistory, "agent_id");
        for (let i = 0; i < Object.keys(grouppedAgents).length; i++) {
          const agent_id = Object.keys(grouppedAgents)[i];
          const agent_history = grouppedAgents[agent_id];
          const agent = agent_history[0].agent;
          let totalKilometers = "-";
          const kilometers = uniqBy(
            agent_history.filter((x) => x.start_kilometer),
            (x) => x.history_status
          );
          if (kilometers.length > 0)
            totalKilometers = kilometers
              .map((item) => item.start_kilometer)
              .reduce((prev, next) => parseFloat(prev) + parseFloat(next));
          let totalHours = "pending";
          try {
            const startTripDate = agent_history.filter(
              (x) =>
                x.history_status == REQUEST_HISTORY.START_TRIP ||
                x.history_status == REQUEST_HISTORY.START_JOB
            )[0].created_at;
            const endTripDate = agent_history.filter(
              (x) => x.history_status == REQUEST_HISTORY.FINISHED
            )[0].created_at;
            totalHours = tripDuration(startTripDate, endTripDate);
          } catch (e) {}
          records.push([
            `${agent.first_name} ${agent.last_name}`,
            totalHours,
            totalKilometers,
            "-",
          ]);
        }
        return records;
      }
    }
    return [];
  }
);

export const getStops = createSelector(
  [requestHistory],
  (requestHistory) => {
    if (requestHistory) {
      return requestHistory.filter(
        (x) => x.history_status === REQUEST_HISTORY.STOP
      );
    }
    return [];
  }
);
export const getPictures = createSelector(
  [requestHistory],
  (requestHistory) => {
    if (requestHistory) {
      return requestHistory.filter(
        (x) => x.history_status === REQUEST_HISTORY.IMAGE
      );
    }
    return [];
  }
);
export const getNotes = createSelector(
  [requestHistory],
  (requestHistory) => {
    if (requestHistory) {
      return requestHistory.filter(
        (x) => x.history_status === REQUEST_HISTORY.NOTE
      );
    }
    return [];
  }
);

export const groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
const groupByCar = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x.request_team.security_car_id] =
      rv[x.request_team.security_car_id] || []).push(x);
    return rv;
  }, {});
};

// export const tripDuration = (date, endDate) => {
//   const startDate = moment(date);
//   const today = moment(endDate)
//   let delta = today.diff(startDate, 'seconds');
//   return moment({h: 0, m: 0, s: 0, ms: 0})
//     .add('second', delta)
//     .format('H:mm');
// };
export const tripDuration = (date, endDate, agentCount) => {
  const startDate = moment(date);
  const today = moment(endDate);
  let d = today.diff(startDate, "seconds");
  if (agentCount) d = d * agentCount;
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var hDisplay = h > 0 ? h + (h == 1 ? " h : " : " h : ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " m " : " m ") : "";
  return hDisplay + mDisplay;
};
export const waitingDuration = (d) => {
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " h : " : " h : ") : "";
  var mDisplay = m > 0 ? (h > 0 ? ":" : "") + m + " m " : "";
  var sDisplay = s > 0 ? (m > 0 ? ":" : "") + s + (s == 1 ? " s " : " s ") : "";

  return hDisplay + mDisplay + sDisplay;
};
export const waitingExcelDuration = (d) => {
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? "" : "") : "0:";
  var mDisplay = m > 0 ? (h > 0 ? ":" : "") + m + "" : "0:";
  var sDisplay = s > 0 ? (m > 0 ? ":" : "") + s + (s == 1 ? "" : "") : "";

  // return hDisplay + mDisplay + sDisplay;
  // return moment.utc(d * 1000).format("HH:mm:ss");
  return moment.duration(d, "seconds").format("HH:mm:ss", {
    trim: false,
  });
};
export const stopDuration = (date, endDate) => {
  const startDate = moment(date);
  const today = moment(endDate);
  let d = today.diff(startDate, "seconds");
  //console.log("duration : ", moment.duration(d));
  return d;
};
